<template>
  <div class="question-history" :style="{height: height, width: width}">
    <div class="header">
      <span>{{ customer.name }}</span>
<!--      <div class="close" @click="closeBox">
        <van-icon name="cross" />
      </div>-->
    </div>
    <div class="content" ref="content">
      <div class="no-more">
        -------- 没有更多消息了 --------
      </div>
      <div class="box" v-for="item in historyQuestionList" :key="item.goodsId">
        <div class="goods" :goodsId="item.goodsId">
          <img :src="item.image" alt="">
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="question-item" :class="{current: question.questionId === parseInt(currentQuestion.questionId)}" v-for="question in item.questions" :key="question.questionId">
          <div class="chat question">
            <div class="chat-list">
              <div class="line" @click="copyText(question.textTranslated)">
                {{ question.textTranslated }}
              </div>
              <div class="block"></div>
              <div class="line" @click="copyText(question.text)">
                {{ question.text }}
              </div>
            </div>
            <div class="date">
              {{ question.dateCreated }}
            </div>
            <div class="link" @click="clickQuestion(question)" v-show="parseInt(currentQuestion.questionId) !== question.questionId">
              <img :src="require('@/assets/images/history-link.png')" alt="">
            </div>
          </div>
          <div class="chat answer" v-if="question.status === 'ANSWERED' ">
            <div class="chat-list">
              <div class="line" @click="copyText(question.answer)">
                {{ question.answer }}
              </div>
            </div>
            <div class="date">
              <span>{{ question.answerDateCreated }}，</span>
              <span v-if="question.answerEnv === -1">mercado</span>
              <span v-else-if="question.answerEnv === 1">PC端-MinoTrend</span>
              <span v-else-if="question.answerEnv === 2">微信端-{{ question.weixinUserInfo ? question.weixinUserInfo.nickname : '昵称获取失败' }}</span>
              <span v-else></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {copyToClipboard} from "@/utils";

export default {
  name: "index",
  props: {
    // 总体高度
    height: {
      type: String,
      default() {
        return 'auto';
      }
    },
    // 总体宽度
    width: {
      type: String,
      default() {
        return '380px';
      }
    },
    // 问题列表
    historyQuestionList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 客户信息
    customer: {
      type: Object,
      default() {
        return {
          name: '',
          siteId: '',
          customerId: '',
        }
      }
    },
    // 当前的问题
    currentQuestion: {
      type: Object,
      default() {
        return {
          customerId: '',
        }
      }
    }
  },
  data() {
    return {

    };
  },
  created() {
    // console.log('currentQuestion', this.currentQuestion);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.content.scrollTo(0, 0)
    }, 200);
  },
  methods: {
    closeBox() {
      this.$emit('close-box')
    },

    clickQuestion(question) {
      this.$emit('click-question', question)
    },

    copyText(text) {
      copyToClipboard(text)
        .then(() => {
          this.$successMessage('已复制', 900);
        })
        .catch(error => {
          console.log('error');
          this.$errorMessage('复制错误，请手动复制');
        })
    },
  }

}
</script>

<style scoped lang="less">
  .question-history{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    .header{
      position: relative;
      padding: 10px 10px;
      border-bottom: 1px solid #f2f2f2;
      span{
        line-height: 23px;
        font-weight: bold;
        color: #7F7F7F;
      }
      .close{
        position: absolute;
        right: 10px;
        top: 8px;
        font-weight: bolder;
        color: #7F7F7F;
        cursor: pointer;
      }
    }
    .content{
      padding: 0 8px 20px;
      flex: 1 1 auto;
      overflow-y: scroll;
      .no-more{
        padding: 10px 0;
        line-height: 20px;
        color: #aaaaaa;
        text-align: center;
        font-size: 10px;
      }
      .box{
        border: 1px solid #e9eaef;
        padding: 8px 0;
        border-radius: 6px;
        margin-bottom: 10px;
        .goods{
          display: flex;
          align-items: center;
          color: #7F7F7F;
          padding: 0 8px 8px;
          img{
            width: 32px;
            height: auto;
            margin-right: 5px;
          }
          .title{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .question-item{
          display: flex;
          flex-direction: column;
          border-radius: 3px;
          padding: 10px 8px;
          margin-bottom: 5px;
          &.current{
            background-color: #FEF5E9;
          }
        }
        .chat{
          border-radius: 6px;
          padding:10px 8px 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          &.question{
            background-color: #F6F6F6;
            color: #333333;
            align-self: flex-start;
            max-width: 100%;
            margin-bottom: 10px;
            .date{
              color: #a9abaf;
            }
            .link{
              right: 8px;
            }
          }
          &.answer{
            align-self: flex-end;
            background-color: #5c90c1;
            color: #ffffff;
            max-width: 80%;
            min-width: 60%;
            .date{
              text-align: right;

            }
          }
          .chat-list{
            .line{
              line-height: 16px;
              cursor: pointer;
            }
            .block{
              white-space: nowrap;
              margin-top: 13px;
              margin-bottom: 13px;
              border-top: 1px dashed #333333;
            }
          }
          .date{
            padding: 10px 0 0;
            font-size: 12px;
          }
          .link{
            position: absolute;
            bottom: 5px;
            cursor: pointer;
            img{
              width: 24px;
              height: auto;
            }
          }
        }
      }
    }
  }
</style>
